$blue: #2b8ccd;

.loading-container {
  min-height: 100px;
}

.loading {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;

  li {
    display: inline-block;
    margin: 0;
    height: 16px;
    width: 16px;
    border: 3px solid $blue;
    border-radius: 100%;
    transform: translateZ(0);
    animation: LOADING 2s infinite;
    &:nth-child(1n) {
      left: -20px;
      animation-delay: 0s;
    }

    &:nth-child(2n) {
      left: 0;
      animation-delay: 0.2s;
    }

    &:nth-child(3n) {
      left: 20px;
      animation-delay: 0.4s;
    }
  }
}

@keyframes LOADING {
  0% {
    transform: scale(0.5);
    background: $blue;
  }

  50% {
    transform: scale(1);
    background: white;
  }

  100% {
    transform: scale(0.5);
    background: $blue;
  }
}
